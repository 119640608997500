<template>
  <div class="WJgroupdevelop">
    <headert></headert>
    <el-row class="content">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="top-banner">
          <img src="../../../assets/image/fazhanbanner.png" alt="">
        </div>
      </el-col>
    </el-row>
    <div>
      <navMenu :activeIndex='menuActiveIndex' :menu='menu' :breadcrumb='breadcrumb' />
      <el-row class=" WJgroupIntroduce-Box" type="flex" justify="center" align='center'>
        <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
          <div class="padding-tb70">
            <div class="WJgroup-titleBox flex">
              <p class="WJgroup-title">{{$t('develop.title')}}</p>
            </div>
          </div>
          <div class='WJgroupDevelopbox'>
            <div class="arrowLeft" @click="moveRight"></div>
            <div class="arrowRight" @click="moveLeft"></div>
            <div class="WJgroupDevelopulBox" ref="atlasulBox"  style="">
              <ul class="WJgroupDevelopUl flex "  :style="{width:liBoxwidth*showList.length+'px',transform :'translateX('+leftMove+'px)'}">
                <template v-for="(item,index) in showList" >
                  <li v-if='index%2==0' :key="index" class="WJgroupDevelopli">
                    <div class="imgbox flex align-end ">
                         <!-- <img style="width: 98px;height: 98px;" :src="imgList[0].img" alt=""> -->
                      <img style="width: 98px;height: 98px;" :src="item.litpic" alt="">
                    </div>
                    <div class="WJgroupDevelopMsg">

                      <h5 v-if="lang=='zh'">{{item.time_md.substr(0,4)}}<span>年/{{item.time_md.substr(5,2)}}月/{{item.time_md.substr(8,2)}}</span></h5>
                      <h5 v-if="lang=='en'"><span>{{item.time_md.substr(5,2)}}/{{item.time_md.substr(8,2)}}/</span>{{item.time_md.substr(0,4)}}</h5>

                      <h6>{{item.title}}</h6>
                      <p>{{item.subTitle}}</p>
                    </div>
                  </li>
                  <li v-if='index%2!=0' class="flex flex_column justify_end align-start" :key="index">
                    <div class="WJgroupDevelopMsg WJgroupDevelopMsgTop">

                      <h5 v-if="lang=='zh'">{{item.time_md.substr(0,4)}}<span>年/{{item.time_md.substr(5,2)}}月/{{item.time_md.substr(8,2)}}</span></h5>
                      <h5 v-if="lang=='en'"><span>{{item.time_md.substr(5,2)}}/{{item.time_md.substr(8,2)}}/</span>{{item.time_md.substr(0,4)}}</h5>

                      <h6>{{item.title}}</h6>
                      <p>{{item.subtitle}}</p>
                    </div>
                    <div class="imgbox">
                        <!-- <img style="width: 98px;height: 98px;" :src="imgList[0].img" alt=""> -->
                        <img style="width: 98px;height: 98px;" :src="item.litpic" alt="">
                    </div>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <div class="hidden-xs">
            <div class="left">
              <div class="left-box flex justify_center">
                <img src="../../../assets/image/develop/shadow.png" alt="" class="left-shadow">
                <div class="left-ring-box">
                  <div class="radioImg-box cl">
                    <img src="../../../assets/image/develop/his-radio.png" alt="" id="radioImg1" class="radioImg"
                      :style="{'transform':'rotate('+rotate+'deg)'  }">
                    <img src="../../../assets/image/develop/his-radio-bg.png" alt="" class="radioImg-bg">
                  </div>
                  <span id="span12" class="r-span2">{{yearPrev}}</span>
                  <span id="span11" class="r-span1">{{yearNext}}</span>
                  <img v-if='rotateIndex>0' src="../../../assets/image/develop/radio-fx_top.png" alt="" class="radio-fx radio-fx_top"
                    style="visibility: visible;">
                  <img v-if='rotateIndex<this.timeList.length-1' src="../../../assets/image/develop/radio-fx_bt.png" alt="" class="radio-fx radio-fx_bt"
                    style="visibility: visible;">
                  <div class="time" id="timer1">{{yearNow}}</div>
                  <div class="prev" id="radioPrev1" @click="handleNext()"  style="display: block;"></div>
                  <div class="next" id="radioNext1" @click="handlePrev()"></div>
                </div>
              </div>
            </div>
          </div>

        </el-col>

      </el-row>
    </div>
    <el-row>

      <el-col>
        <footerb></footerb>
      </el-col>

    </el-row>
    <!-- 底部 -->

  </div>
</template>

<script>
  export default {
    name: "WJgroupIntroduce",
    components: {},
    data() {
      return {
        menuActiveIndex: '/WJgroupDevelop',
        menu: {
          name: '维佳集团',
          path: '/WJgroupIntroduce',
          list: [{
            name: this.$t('menu.Introduction'),
            path: '/WJgroupIntroduce',
          }, {
            name: this.$t('menu.Message'),
            path: '/WJgroupSendWord',
          },{
            name: this.$t('menu.develop'),
            path: '/WJgroupDevelop',
          }, {
            name:this.$t('menu.honour'),
            path: '/WJgroupHonor',
          }, {
            name:this.$t('menu.team'),
            path: '/WJgroupTeam',
          }]
        },
        //
        breadcrumb: [{
          name: this.$t('menu.group'),
          path: '/WJgroupIntroduce',
        }, {
          name: this.$t('menu.develop'),
          path: '/WJgroupDevelop',
        }],
        timeList: [
        ],
        liheight: "228px",
        activeLi: '',
        rotate: 0,
        rotateIndex: 0,
        yearNow: "2019",
        yearPrev: '',
        yearNext: '2018',
        typeid:null,
        showList:[],
        leftMove:0,
        liBoxwidth:450,
        ulwidth:0,
        imgList:[{img:require("@/assets/image/icons/icons1.png")}],
      }
    },
    computed: {
      lang(){
        return window.localStorage.getItem('language')
      },
    },
    created() {
      this.getYearList();
      this.getData();
    },
    mounted() {
      this.$nextTick(() => {
        this.activeLi = 'A'
      })
    },
    methods: {
      getYearList(){
        this.$init.getChannelList(channelList=>{
          let yearList = channelList.filter(item=>{
            return item.parent_id==73
          });

          if(yearList&& yearList.length>0){
            let timeList =[];
            yearList.forEach(item=>{
              timeList.push({
                ...item,
                year:item.typename,
                list:[]
              })
            })
            this.timeList =timeList;

            this.yearNow = yearList[0].typename;
            console.log('this.yearNow',this.yearNow)
            if(yearList.length>1){
              this.yearNext = yearList[1].typename;
            }
            this.typeid = yearList[0].id

          }

        })
      },
      getData(){
        let api='?m=api&c=v1.Api&a=index&_ajax=1';
        this.$request.post(api,{
          apiArclist_1:`ekey=1&typeid=73&limit=200&addfields=time_md,title_en,subtitle_en`,//获取栏目文章列表
        }).then(res=>{

          this.showList =this.$utils.translate(res.apiArclist[1].data);

        })
      },
     moveLeft() {
       let ulBoxwidth = this.$refs.atlasulBox.offsetWidth;
       this.ulwidth=this.liBoxwidth*this.showList.length - 1
       let moveWith = ulBoxwidth - this.ulwidth;
       if (this.leftMove > moveWith) {
         let Move = Math.abs(moveWith - this.leftMove)
         if (Move < this.liBoxwidth) {
           this.leftMove = this.leftMove - Move
         } else {
           this.leftMove = this.leftMove - this.liBoxwidth
         }


       }

     },
     moveRight() {
       let ulBoxwidth = this.$refs.atlasulBox.offsetWidth;
        this.ulwidth=this.liBoxwidth*this.showList.length - 1
       let moveWith = ulBoxwidth - this.ulwidth;
       let moveOne = this.ulwidth / (this.showList.length - 1)
       if (this.leftMove < 0) {
         // let Move = Math.abs(moveWith - this.leftMove)
         let Move = Math.abs(this.leftMove)
         if (Move<this.liBoxwidth) {
           this.leftMove = 0
         } else {
           this.leftMove = this.leftMove + this.liBoxwidth
         }
       }
     },
      handlePrev() {

        if (this.rotateIndex >= 0 && this.rotateIndex <= this.timeList.length - 2) {

          this.rotate = this.rotate * 1 - 90;
          this.rotateIndex++;
          this.yearNow = this.timeList[this.rotateIndex].year;
          this.typeid = this.timeList[this.rotateIndex].id
          //
          this.yearPrev = this.timeList[this.rotateIndex - 1].year;

          let ulBoxwidth = this.$refs.atlasulBox.offsetWidth;
          this.ulwidth=this.liBoxwidth*this.showList.length - 1;
          let moveWith =Math.abs(ulBoxwidth - this.ulwidth);
          let sindex=this.showList.findIndex(item=>item.typename==this.yearNow)
          let moveI=this.liBoxwidth*sindex;

          if(moveI>this.liBoxwidth&&moveWith>moveI){
             this.leftMove=-moveI;
          }
          console.log(1)
          if (this.rotateIndex <= this.timeList.length - 2) {
            this.yearNext = this.timeList[this.rotateIndex + 1].year;
          } else {
            this.yearNext = "";
          }
        }

      },
      handleNext() {

        if (this.rotateIndex > 0) {
          this.rotate = this.rotate * 1 + 90;
          this.rotateIndex--;

          this.yearNow = this.timeList[this.rotateIndex].year;
          this.typeid = this.timeList[this.rotateIndex].id
          this.yearNext = this.timeList[this.rotateIndex + 1].year;

          let ulBoxwidth = this.$refs.atlasulBox.offsetWidth;
          this.ulwidth=this.liBoxwidth*this.showList.length - 1;
          let moveWith =Math.abs(ulBoxwidth - this.ulwidth);
          let sindex=this.showList.findIndex(item=>item.typename==this.yearNow)
          let moveI=this.liBoxwidth*sindex;

          if(moveI>=0&&moveWith>moveI){
             this.leftMove=-moveI;
          }else if(moveI<=0){
              this.leftMove=0;
            }

          if (this.rotateIndex > 0) {
            this.yearPrev = this.timeList[this.rotateIndex - 1].year;
          } else {
            this.yearPrev = "";
          }
        }
      },
      closeLi(index) {
        if (index == this.activeLi) {
          this.activeLi = 'A'
        } else {
          this.activeLi = index
        }
        // this.liheight=0
      },
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log("slide change");
      }
    }
  };
</script>
<style lang="less" scoped>
  .WJgroupIntroduce-Box {
    background-image: url('../../../assets/image/develop/image280.png');
    background-repeat: no-repeat;
    background-position: 0% 25%;
    padding-bottom: 0 !important;
    overflow: hidden;

  }

  .WJgroupDevelopulBox {
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .WJgroupDevelopulBox::-webkit-scrollbar {
    display: none !important;
  }

  .WJgroupDevelopbox {
    margin: 70px 0 220px 100px;
    position: relative;

  }

  .arrowLeft {
    position: absolute;
    top: 50%;
    margin-top: -36px;
    left: -68px;
    width: 55px;
    height: 55px;
    border-bottom: 1px solid #A4825F;
    border-left: 1px solid #A4825F;
    transform: rotate(45deg);
    cursor: pointer;
  }

  .arrowRight {
    cursor: pointer;
    position: absolute;
    top: 50%;
    margin-top: -36px;
    right: -68px;
    width: 55px;
    height: 55px;
    border-top: 1px solid #A4825F;
    border-right: 1px solid #A4825F;
    transform: rotate(45deg)
  }

  .WJgroupDevelopUl {
    height: 534px;
    position: relative;
    transition: 1s;
  }

  .WJgroupDevelopUl::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #CECECE;
  }

  .WJgroupDevelopUl li {
    width: 450px;
    height: 100%;
    padding: 0 20px;
    position: relative;
    box-sizing: border-box;
  }

  .WJgroupDevelopUl li::before {
    content: '';
    top: 50%;
    margin-top: -4px;
    left: 20px;
    position: absolute;
    width: 12px;
    height: 12px;
    background: #7E7049;
    border-radius: 50%;
    z-index: 99;
  }

  .WJgroupDevelopUl li .imgbox {
    width: 335px;
    height: 220px;
  }

  .WJgroupDevelopUl li .imgbox img {
    width: 100%;
    height: 100%;
  }

  .WJgroupDevelopMsgTop {
    margin-bottom: 85px;
  }

  .WJgroupDevelopMsg {
    margin-top: 85px;
  }

  .WJgroupDevelopMsg h5 {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 3.2px;
    color: #1F2D39;
  }

  .WJgroupDevelopMsg h5 span {
    font-size: 16px;
  }

  .WJgroupDevelopMsg h6 {
    // font-family: 'OPPOSans-M';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 2.4px;
    color: #1F2D39;
    margin-top: 11px;
    margin-bottom: 9px;
  }

  .WJgroupDevelopMsg p {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 2.4px;
    color: #000000;
  }

  .WJgroupdevelop-time::-webkit-scrollbar {
    display: none;
  }




  .left {
    // height: 530px;
    width: 100%;
    margin: 0 auto;
  }

  .left-box {
    width: 100%;
    height: auto;
    // padding: 135px 0 210px 0;
    border-bottom: solid 1px #e9e9e9;
    position: relative;
    // overflow: hidden;
  }

  .left-box .left-shadow {
    height: 77px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    // transform: translateY(-50%);

    display: block;
    z-index: 1;
    pointer-events: none;
  }


  .left-ring-box {
    height: 246.5px;
    width: 493px;
    position: relative;
  }

  .radioImg-box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }

  .left-ring-box img.radioImg {
    display: block;
    width: 100%;
    transition: all .36s ease;

    max-width: none;
    position: relative;
    z-index: 2;
  }


  .left-ring-box img.radioImg-bg {
    position: absolute;
    display: block;
    width: 98%;
    top: 0;
    right: 2%;
    max-width: none;

  }



  .left-ring-box span.r-span1 {
    top: 0px;
    right: -4px;
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #979797;
    transform: rotate(45deg);
  }

  .left-ring-box span.r-span2 {
    top: 0px;
    left: -4px;
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #979797;
    transform: rotate(-45deg);
  }

  .left-ring-box span {
    position: absolute;
    font-size: 18px;
    line-height: 28px;
  }

  .left-ring-box span.r-span3 {
    bottom: 20px;
    left: 188px;
  }

  .left-ring-box span.r-span4 {
    bottom: -33px;
    left: 11px;
  }

  .left-box img.radio-fx.radio-fx_top {

    animation: gelatine 2.5s infinite;
    right: 52%;
  }

  .left-box img.radio-fx.radio-fx_bt {
    animation: gelatine 2.5s infinite;
    left: 50%;
  }


  .left-box img.radio-fx {
    position: absolute;
    top: 44px;
  }

  @keyframes gelatine {
    80% {
      opacity: 0;
      transform: scale(1.1);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .left-ring-box .time {
    position: absolute;
    top: -66px;
    left: 50%;
    margin-left: -58px;
    font-size: 42px;
    line-height: 52px;
    transform: translateY(-50%);
    font-weight: bold;
  }

  .left-ring-box .prev {
    height: 300px;
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 3;
  }

  .left-ring-box .next {
    width: 50%;
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    z-index: 3;
  }

  @media screen and (max-width: 1600px) {
    .left {
      width: 424px;
    }

    .left-box {
      // padding: 108px 0 168px 0;
    }

    .left-ring-box {
      width: 435px;
      height: 217px;
    }

    //.left-ring-box span.r-span1 {
    //  top: -33px;
    //  left: 10px;
    //}

    .left-ring-box span {
      font-size: 16px;
      line-height: 24px;
    }

    .left-ring-box span {
      font-size: 16px;
      line-height: 24px;
    }

    .left-ring-box span.r-span3 {
      bottom: 35px;
      left: 165px;
    }

    .left-ring-box span.r-span4 {
      bottom: -33px;
      left: 10px;
    }

    .left-box img.radio-fx {
      height: 22px;
      left: 150px;
    }

    .left-ring-box .time {
      font-size: 33px;
      left: 195px;
    }
  }

  @media screen and (max-width: 1650px) {
    .left {
      width: 430px;
    }
  }
  @media screen and (min-width:320px) and (max-width:767px) {
    .WJgroupDevelopbox{
      margin-left: 0;
    }
    .WJgroupdevelop .WJgroupDevelopUl{
      display:block;
      width: 100%!important;
      transform: none!important;
      height: auto;
    }
    .WJgroupdevelop .WJgroupDevelopUl li{

      margin: 0;
      padding:30px 0px;
      width: auto;
      min-height: 340px;
      align-items: flex-start;
      justify-content: flex-start;
      &:nth-child(2n-1){
        .WJgroupDevelopMsg{
          text-align: right;
          width: 46%;
        }
        .imgbox{
          position: absolute;
          top:40px;
          left: 54%;
        }
      }
      &:nth-child(2n){
        .WJgroupDevelopMsg{
          position: absolute;
          top:40px;
          left: 54%;
          width: 42%;
        }
        .imgbox{
          position: absolute;
          top:40px;
          right: 54%;
          text-align: right;

        }
      }
    }
    .WJgroupdevelop .WJgroupDevelopUl li.WJgroupDevelopli{
      display: flex;
      flex-direction: column-reverse!important;
      justify-content: flex-end;
    }
    .WJgroupdevelop .WJgroupDevelopMsg{
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .WJgroupdevelop .WJgroupDevelopUl li:before{
      content: '';
      top:4px;
      left: 50%;
      margin-left: -4px;
    }
    .WJgroupdevelop .WJgroupDevelopUl li:after{
      content: '';
      position: absolute;top:0;
      left: 50%;

      height: 100%;
      width: 1px;
      background-color: #7E7049;
    }
    .WJgroupDevelopUl::after{
      display: none;
    }
    .WJgroupdevelop .WJgroupDevelopUl li .WJgroupDevelopMsg h5{
      font-size: 39px;
      span{
        font-size: 34px;
      }
    }
    .WJgroupdevelop .WJgroupDevelopUl li .WJgroupDevelopMsg h6{
      font-size: 34px;
      line-height: 44px;
    }
  }
</style>
